<template>
  <div>
    <div class="viewcontent">
      <form @submit.prevent="processRegist()" class="regist toolform">
        <div class="">
          <div class="inputcont" :class="checkData('password')">
            <input
              id="password"
              class="border_category tertiary_color"
              type="password"
              autocomplete="on"
              v-model="data.password"
              :placeholder="translations.form_ph_password"
            />
            <button
              type="button"
              @mouseover="hoverWarning('password')"
              @mouseleave="leaveWarning('password')"
            >
              <i class="fas fa-exclamation-triangle warning_color"></i>
            </button>
          </div>
          <div class="inputcont" :class="checkData('password')">
            <input
              id="password2"
              class="border_category tertiary_color"
              type="password"
              autocomplete="on"
              v-model="data.secPassword"
              :placeholder="translations.form_ph_retype_password"
            />
            <button
              type="button"
              @mouseover="hoverWarning('password')"
              @mouseleave="leaveWarning('password')"
            >
              <i class="fas fa-exclamation-triangle warning_color"></i>
            </button>
          </div>
          <div class="warningcont" v-if="warning.password">
            <p>
              {{ translations.warning_password }}
            </p>
          </div>
        </div>
        <button type="submit" class="btn primary_bg_color contrast_color">
          {{ translations.btn_register }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import md5 from 'js-md5'

  export default {
    components: {},
    data() {
      return {
        warning: {
          password: false,
          repassword: false,
        },
        data: {
          password: null,
          secPassword: null,
        },
        preview: '',
        errorsform: [],
      }
    },
    computed: {
      ...mapState(['userRegister', 'translations']),
    },
    methods: {
      ...mapActions(['editPasswordUser', 'changeToolSelected']),
      processRegist() {
        this.errorsform = []
        if (
          !this.data.password ||
          (this.data.password && this.data.password.length < 10)
        ) {
          this.errorsform.push('error_password')
        }
        if (this.data.password !== this.data.secPassword) {
          this.errorsform.push('error_repassword')
        }
        if (this.errorsform.length === 0) {
          this.editPasswordUser({
            password: md5(this.data.password),
            token: this.$route.params.token,
            action: this.$route.params.action,
          })
        } else {
          console.log(this.errorsform)
        }
      },
      hoverWarning(type) {
        switch (type) {
          case 'password':
            this.warning.password = true
            break
          case 'repassword':
            this.warning.repassword = true
            break
        }
      },
      leaveWarning(type) {
        switch (type) {
          case 'password':
            this.warning.password = false
            break
          case 'repassword':
            this.warning.repassword = false
            break
        }
      },
      checkData(value) {
        let clas = ''
        let validate = null
        switch (value) {
          case 'password':
            if (this.data.password !== null) {
              validate = this.data.password.length > 10
            }
          case 'repassword':
            if (this.data.secPassword !== null) {
              validate =
                this.data.secPassword.length > 10 &&
                this.data.secPassword == this.data.password
            }
            break
        }

        if (validate !== null && validate) {
          clas = 'valid'
        } else if (validate !== null && !validate) {
          clas = 'invalid'
        }

        return clas
      },

      cloneInfo() {
        this.data = { ...this.userRegister }
      },
    },

    async created() {
      console.log(this.$route.params)
      this.changeToolSelected(null)
    },
  }
</script>

<style scoped>
  /* .checkbox {
    background-color: #a7a7a782;
  } */
  .registcont {
    height: 100%;
  }
  .checkbox input {
    width: 16px;
    height: 16px;
    margin: auto;
    margin-right: 5px;
    /* display: inline-block;
    vertical-align: middle; */
  }
  .checkbox label {
    width: 100%;
    vertical-align: text-bottom;
  }
  .preview {
    /* border: 1px solid #303030; */
    padding: 5px;
    position: relative;
    height: 150px;
    width: 150px;
    margin: 0 auto;
  }
  .selector {
    width: 60%;
  }
  .preview img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20px auto;
  }

  .regist {
    justify-content: center;
    height: 100%;
  }

  .userinfo,
  .businessinfo {
    width: 50%;
    display: inline-block;
  }
  .businessinfo {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    height: 100%;
  }

  .descriptionform {
    position: absolute;
    z-index: 1;
  }
  .buttonsform {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  h2 {
    margin-top: 40px;
  }
  .terms {
    font-weight: bold;
  }

  .inputcont {
    position: relative;
  }
  .inputcont button {
    position: absolute;
    top: 40%;
    right: 20%;
    display: none;
    height: auto;
  }
  .invalid button {
    display: block;
  }

  .warningcont {
    height: 20px;
  }
  @media screen and (max-width: 1000px) {
    .userinfo,
    .businessinfo {
      display: block;
      width: 100%;
    }
    .userinfo {
      background-color: #fff;
    }
    .registcont {
      height: 2000px;
      background-color: #a7a7a7;
    }
    .buttonsform {
      background-color: #a7a7a7;
    }
    /* .previewspace {
      overflow: hidden;
      overflow-y: scroll;
      height: 90%;
    } */
    .previewspace .businessinfo {
      height: 625px;
    }
    .regist {
      display: block;
      overflow-y: scroll;
      height: 700px;
    }
    .descriptionform {
      position: relative;
    }
    /* .buttonsform {
      position: fixed;
    } */
    h2 {
      margin-top: 15px;
      padding-top: 20px;
    }
    .userinfo h2 {
      margin-top: 0;
    }
    .businessinfo {
      height: 565px;
    }
    .inputcont button {
      right: 10%;
    }
  }
  @media screen and (max-width: 800px) {
    .inputcont button {
      right: 5%;
    }
  }
  @media screen and (max-width: 670px) {
    .businessinfo {
      height: 540px;
    }
    .buttonsform {
      bottom: 0;
      background-color: #a7a7a7;
    }
  }
</style>
